<div class="row">

    <div style="float: left;">
        <button nbButton class="btn-event" style="margin-left: 5px;" (click)="showPzoneTracksList()">Pzone
            Tracks</button>
        <button nbButton class="btn-event" style="margin-left: 5px;" (click)="showProviderTracksList()">Provider
            Tracks</button>
    </div>

    <span style="float: right;">
        <button nbButton class="btn-event" status="success" (click)="logout()">Log Out</button>
    </span>

</div>