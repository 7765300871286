import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from 'angularfire2/auth';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ROUTERS } from '../app.constant';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  unsubscribe:any;
  constructor(public jwtHelper: JwtHelperService,
    private firebaseAuth: AngularFireAuth, private router: Router, private spinner: NgxSpinnerService) {

  }

  init() {
    this.onChnageToken().then(user => {
      if (user) {
        this.router.navigateByUrl(ROUTERS.PZONE_TRACKS);
      }
    });

  }
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
  }
  getFileHeaders() {
    return new Headers({
      'Accept': 'application/json',
      'Authorization': localStorage.getItem('token')
    });
  }
  onChnageToken() {
    if(this.unsubscribe){
      this.unsubscribe();
    }
    this.unsubscribe=this.firebaseAuth.auth.onIdTokenChanged(change => {
      if (change && change['xa']) {
        localStorage.setItem('token', change['xa']);
      }
    });

    return new Promise<any>((resolve, reject) => {
      let unsubscribe= this.firebaseAuth.auth.onAuthStateChanged(user => {
        if (user) {
          firebase.auth().currentUser.getIdToken().then((token) => localStorage.setItem('token', token));
          if(unsubscribe){
            unsubscribe();
          }
          return resolve(user);
        }
      }, error => {
        return reject(error);
      });
    });

  }

  login(email: string, password: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
        firebase.auth().signInWithEmailAndPassword(email, password).then(user => {
          if (user) {
            localStorage.setItem("userId", user.user.uid);
            firebase.auth().currentUser.getIdToken().then((token) => localStorage.setItem('token', token));

            return resolve(user);
          }
        }).catch(err => {
          return reject(err);
        })
      })
    })
  }

  logout() {
    this.spinner.show();


    localStorage.clear();
    this.firebaseAuth
      .auth
      .signOut();
    this.spinner.hide();
    this.router.navigateByUrl(ROUTERS.LOGIN);



  }
  getTokenAuthHeaders() {
    let token = localStorage.getItem("token");
    if (!token) {
      this.logout();
    }
    const header = new Headers();
    header.set('Authorization', token)

    let tokenOptions = new RequestOptions({
      headers: header

    });
    return tokenOptions;


  }

}
