import { Component, EventEmitter, Output } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { Router } from '@angular/router';
import { ROUTERS } from 'src/app/app.constant';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Output() showTracks: EventEmitter<string> = new EventEmitter<string>();
  isPzone: boolean = false;
  isProvider: boolean = false;
  constructor(private router: Router, private authService: AuthService) {

  }
  showPzoneTracksList() {
    this.isPzone = true;
    this.showTracks.emit('pzone_tracks');
  }
  showProviderTracksList() {
    this.isProvider = true
    this.showTracks.emit('provider_tracks');
  }
  logout() {
    this.authService.logout();
  }
}
