<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-clip-rotate-pulse">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<p-toast position="top-right" [showTransformOptions]="'translateX(100%)'" [baseZIndex]="5000"></p-toast>

<div class="limiter">
  <div class="container-login100" style="background-image: url('../../../assets/rsz_bg2.jpg');">
    <div class="wrap-login100 p-t-30 p-b-50">
      <span class="login100-form-title p-b-41">
        Track Manager
      </span>
      <form class="login100-form validate-form p-b-33 p-t-5">
        <div *ngIf="message" class="error_message">
          <label class="error_message" style="color: red" for="exampleInputEmail1">{{message}} </label>
        </div>
        <div class="wrap-input100 validate-input" data-validate="Enter username">
          <input class="input100" type="text" name="username" placeholder="User name" [(ngModel)]="email" required
            [ngModelOptions]="{standalone: true}">
          <span class="focus-input100" data-placeholder="&#xe82a;"></span>
        </div>

        <div class="wrap-input100 validate-input" data-validate="Enter password">
          <input class="input100" type="password" name="pass" placeholder="Password" [(ngModel)]="password" required
            [ngModelOptions]="{standalone: true}">
          <span class="focus-input100" data-placeholder="&#xe80f;"></span>
        </div>

        <div class="container-login100-form-btn m-t-32">
          <button class="login100-form-btn" (click)="login()">
            Login
          </button>
        </div>

      </form>
    </div>
  </div>
</div>