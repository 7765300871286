export const environment = {
  production: false,
  // firebase: {
  //   apiKey: "AIzaSyBJTiFLtkgHS49puuuv5A3xH6EKLtLMnWQ",
  //   authDomain: "such-new.firebaseapp.com",
  //   databaseURL: "https://such-new-default-rtdb.firebaseio.com",
  //   projectId: "such-new",
  //   storageBucket: "such-new.appspot.com",
  //   messagingSenderId: "455916163711",
  //   appId: "1:455916163711:web:9446daa3deab18a0cfa54a"
  // }
  firebase: {
    apiKey: "AIzaSyBxmIN4Tsgy17-1mDxMeCbNEcb312GLO8U",
    authDomain: "bm-trackmanager.firebaseapp.com",
    databaseURL: "https://bm-trackmanager-default-rtdb.firebaseio.com",
    projectId: "bm-trackmanager",
    storageBucket: "bm-trackmanager.appspot.com",
    messagingSenderId: "625514310687",
    appId: "1:625514310687:web:c9f4adb294024ad962792a",
  },
};
