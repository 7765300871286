import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { AuthService } from './service/auth.service';
import { Router } from '@angular/router';
import { RESTSERVER, ROUTERS } from './app.constant';
import { DashboardService } from './service/dashboard.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  constructor(public auth: AuthService, private router: Router, private db: AngularFirestore, private dashboardService: DashboardService) {
  }

  ngOnInit(): void {
    this.db.firestore.collection("SERVER").doc("TRACK").get().then(res => {

      if (res) {
        let data = res.data();
        RESTSERVER.SERVER = data['REST'];
        this.auth.onChnageToken().then(user => {
          if (user) {
            this.dashboardService.indexOf = 0;
            this.dashboardService.providerIndex = 0;
            this.dashboardService.init();
            this.dashboardService.getAllProvincesData().then(res => {
              if (res) {
                this.dashboardService.getAllTrackCode().then(res => {
                  if (res) {
                    this.dashboardService.getTracksByProvince(this.dashboardService.provinces[0]);
                  }
                });
              }
            })
            this.dashboardService.getAllProviders().then(res => {
              this.dashboardService.getTrackByProvider(this.dashboardService.providers[0]);
            });

            if (this.router.url != '/provider_tracks') {
              this.router.navigateByUrl(ROUTERS.PZONE_TRACKS);
            }

          } else {
            localStorage.clear();
            this.router.navigateByUrl(ROUTERS.LOGIN);
          }

        }, error => {
          localStorage.clear();
          this.router.navigateByUrl(ROUTERS.LOGIN);
        });
      }
    });
    if (!this.auth.isAuthenticated()) {
      return;
    }



  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
