<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-clip-rotate-pulse">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<nb-layout>
    <nb-layout-header fixed>
        <app-header (showTracks)="showLayout($event)" style="width: 100%;"></app-header>
    </nb-layout-header>
    <nb-layout-column>
        <p-toast position="top-right" [showTransformOptions]="'translateX(100%)'" [baseZIndex]="5000"></p-toast>
        <p-tabView (onChange)="onTabChange($event)">
            <p-tabPanel *ngFor="let provider of dashboardService.providers let i = index"
                [selected]="i==dashboardService.providerIndex">
                <ng-template pTemplate="header">
                    <span>{{provider}}</span>
                </ng-template>
            </p-tabPanel>
            <div class="table-css">
                <p-table #dt [columns]="cols" [value]="dashboardService.providerTrackList" [paginator]="true"
                    [rows]="20" [responsive]="true">
                    <ng-template pTemplate="caption">
                        <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-6">

                                <span style="float: left;">
                                    <div class="row">
                                        <div class="col-xs-6 col-md-6">
                                            <button type="button" pButton label="Export" (click)="exportCSV()"></button>
                                        </div>
                                        <div class="col-xs-6 col-md-6">
                                            <p-fileUpload mode="basic" multiple="multiple" name="demo[]"
                                                url="./upload.php" [auto]="true" accept=".csv" maxFileSize="1000000"
                                                (onSelect)="onUpload($event)" chooseLabel="Import"></p-fileUpload>
                                        </div>

                                    </div>

                                </span>
                            </div>
                            <div class="col-xs-12 col-sm-4 col-md-4">
                            </div>
                            <div class="col-xs-12 col-sm-3 col-md-3">
                                <span style="float: right;font-size: 14px;">
                                    <input type="text" pInputText
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Global Search" />
                                </span>
                            </div>
                        </div>


                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th style="text-align: center;" *ngFor="let col of columns" [pSortableColumn]="col.field"
                                [ngStyle]="{'width':col.width}">
                                {{col.header}}
                                <label *ngIf="col.field !='action'">
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </label>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr>
                            <td *ngFor="let col of columns">
                                <span style="float: left;" class="ui-column-title">{{col.header}}</span>
                                {{rowData[col.field]}}
                                <label *ngIf="col.field =='action'">
                                    <button type="button" pButton icon="pi pi-pencil"
                                        (click)="showDialogToEdit(rowData)" label="Edit"></button>
                                </label>
                            </td>
                        </tr>
                    </ng-template>

                </p-table>
            </div>
            <p-dialog header="Map Pzone Code" [(visible)]="displayDialog" [focusOnShow]="false" [responsive]="true"
                *ngIf="isEdit" showEffect="fade" [modal]="true" [style]="{width: '400px'}">
                <div class="ui-g ui-fluid">
                    <div class="ui-g-12">
                        <div class="ui-g-4">
                            <label for="providerCode">Provider Code</label>
                        </div>
                        <div class="ui-g-8">
                            <input id="providerCode" type="text" class="form-control"
                                [(ngModel)]="providerTrack.providerCode" pInputText disabled>
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-4">
                            <label for="province">Provider</label>
                        </div>
                        <div class="ui-g-8">
                            <input id="province" type="text" class="form-control" [(ngModel)]="providerTrack.provider"
                                pInputText disabled>
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-4">
                            <label for="pzonecode">pzoneCode</label>
                        </div>
                        <div class="ui-g-8">
                            <p-dropdown appendTo="body" inputId="state" [options]="dashboardService.pzoneCodeList"
                                [(ngModel)]="selectedPzonecode" [styleClass]="'dropdown-css'"
                                placeholder="Select PzoneCode" filter="true" [disabled]="selectedPzonecode">
                            </p-dropdown>
                           
                        </div>
                    </div>
                </div>

                <p-footer>
                    <div class="ui-dialog-buttonpane ui-helper-clearfix">
                        <button type="button" pButton icon="pi pi-check" (click)="edit()" label="Save"></button>
                    </div>
                </p-footer>
            </p-dialog>
        </p-tabView>
    </nb-layout-column>
</nb-layout>





<!-- <div class="table-css">
    <p-table #dt [columns]="cols" [value]="dashboardService.providerTrackList" [paginator]="true" [rows]="20"
        [responsive]="true">
        <ng-template pTemplate="caption">
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6">
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6">
                    <span style="float: right;font-size: 14px;">
                        <input type="text" pInputText (input)="dt.filterGlobal($event.target.value, 'contains')"
                            placeholder="Global Search" />
                    </span>
                </div>
            </div>


        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="text-align: center;" *ngFor="let col of columns" [pSortableColumn]="col.field"
                    [ngStyle]="{'width':col.width}">
                    {{col.header}}
                    <label *ngIf="col.field !='action'">
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </label>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns">
                    <span style="float: left;" class="ui-column-title">{{col.header}}</span>
                    {{rowData[col.field]}}
                    <label *ngIf="col.field =='action'">
                        <button type="button" pButton icon="pi pi-pencil" (click)="showDialogToEdit(rowData)"
                            label="Edit"></button>
                    </label>
                </td>
            </tr>
        </ng-template>

    </p-table>
</div>
<p-dialog header="Map Pzone Code" [(visible)]="displayDialog" [focusOnShow]="false" [responsive]="true" *ngIf="isEdit"
    showEffect="fade" [modal]="true" [style]="{width: '400px'}">
    <div class="ui-g ui-fluid">
        <div class="ui-g-12">
            <div class="ui-g-4">
                <label for="providerCode">Provider Code</label>
            </div>
            <div class="ui-g-8">
                <input id="providerCode" type="text" class="form-control" [(ngModel)]="providerTrack.providerCodeStr"
                    pInputText disabled>
            </div>
        </div>
        <div class="ui-g-12">
            <div class="ui-g-4">
                <label for="province">Provider</label>
            </div>
            <div class="ui-g-8">
                <input id="province" type="text" class="form-control" [(ngModel)]="providerTrack.provider" pInputText
                    disabled>
            </div>
        </div>
        <div class="ui-g-12">
            <div class="ui-g-4">
                <label for="pzonecode">pzoneCode</label>
            </div>
            <div class="ui-g-8">
                <p-dropdown appendTo="body" inputId="state" [options]="dashboardService.pzoneCodeList"
                    [(ngModel)]="selectedPzonecode" [styleClass]="'dropdown-css'" placeholder="Select PzoneCode"
                    filter="true"></p-dropdown>
            </div>
        </div>
    </div>

    <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <button type="button" pButton icon="pi pi-check" (click)="edit()" label="Save"></button>
        </div>
    </p-footer>
</p-dialog> -->