import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  dataString: string;

  constructor(public datePipe: DatePipe,
    public userService: UserService) {
  }

  ngOnInit(): void {

  }

}
