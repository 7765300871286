import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    errorStatus: string = 'error';
    successStatus: string = 'info';
    constructor(public messageService: MessageService) { }
 
    showToast(type, error: string) {
        this.messageService.clear();
        this.messageService.add({ severity: type, summary: error });

    }

    errorMessage(err) {
        if (err["status"] == 0) {
            this.showToast(this.errorStatus, "SERVER NOT FOUND");
        } else {
            this.showToast(this.errorStatus, JSON.parse(err['_body']).message);
        }
    }


}