export const RESTSERVER = {
    SERVER: '',
};

export const ROUTERS = {
    LOGIN: 'login',
    DASHBOARD: 'tracks',
    PZONE_TRACKS: 'pzone_tracks',
    PROVIDER_TRACKS: 'provider_tracks',
    BET_HISTORY: 'bet_history',
    TRANSACTION_HISTORY: 'transaction_history'
}