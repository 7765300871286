import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuardService } from './service/auth-guard.service';
import { ROUTERS } from './app.constant';
import { TrackListComponent } from './components/track-list/track-list.component';
import { ProviderTrackListComponent } from './components/provider-track-list/provider-track-list.component';


const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: ROUTERS.LOGIN,
    component: LoginComponent,
  },

  // {
  //   path: ROUTERS.DASHBOARD,
  //   component: DashboardComponent,
  //   canActivate: [AuthGuardService]

  // }
  {
    path: ROUTERS.PZONE_TRACKS,
    component: TrackListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: ROUTERS.PROVIDER_TRACKS,
    component: ProviderTrackListComponent,
    canActivate: [AuthGuardService]
  }
];
const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
