import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { DashboardService } from 'src/app/service/dashboard.service';
import { CommonService } from 'src/app/service/common.service';
import { PzoneCode } from 'src/app/model/pzoneCode';
import { ExportToCsv } from 'export-to-csv';
import { NgxSpinnerService } from 'ngx-spinner';
import { PzoneTrack } from 'src/app/model/pzoneTrack';
import { PzoneCodeDto } from 'src/app/model/pzoneCodeDto';
import { ROUTERS } from 'src/app/app.constant';
import { Router } from '@angular/router';
@Component({
  selector: 'app-track-list',
  templateUrl: './track-list.component.html',
  styleUrls: ['./track-list.component.scss']
})
export class TrackListComponent implements OnInit {

  selectedTrackCode: string;
  selectedTrackName: string;
  selectedEventType: string;
  selectedTimezone: string;
  displayDialog: boolean = false;
  cols: any;
  province: string;
  selectedFile: File;
  pzoneTrack: PzoneTrack = new PzoneTrack();
  pzoneCode: PzoneCode = new PzoneCode();
  constructor(private router: Router, public dashboardService: DashboardService, private commonService: CommonService, private spinner: NgxSpinnerService) {
  }

  showLayout(data) {
    if (data == ROUTERS.PROVIDER_TRACKS) {
      this.router.navigateByUrl(ROUTERS.PROVIDER_TRACKS)
    }
  }
  onTabChangeProvince(event) {
    this.dashboardService.indexOf = event.index;
    let provinces = this.dashboardService.provinces[event.index];
    this.dashboardService.getTracksByProvince(provinces);
  }
  ngOnInit(): void {

    this.cols = [
      { field: 'pzoneCode', header: 'Pzone Code' },
      { field: 'trackName', header: 'Track Name' },
      { field: 'timezone', header: 'Timezone' },
      { field: 'province', header: 'Province' },
      { field: 'eventType', header: 'Event Type' }
    ];
  }
  save() {
    if (this.province && this.selectedEventType && this.selectedTimezone && this.selectedTrackName) {
      this.spinner.show();
      this.onTrackNameChange(this.selectedTrackName);
      this.pzoneTrack = new PzoneTrack();
      this.pzoneCode.eventType = this.selectedEventType;
      this.pzoneCode.province = this.province;
      this.pzoneTrack.timezone = this.selectedTimezone;
      this.pzoneTrack.pzoneCode = this.dashboardService.getPzoneCode(this.selectedTrackName, this.pzoneCode.eventType, this.pzoneCode.province);
      this.pzoneTrack.trackName = this.selectedTrackName;



      this.dashboardService.createTrackCode(this.pzoneTrack).subscribe(data => {
        if (data) {
          let track: PzoneTrack = data.json();
          let pzoneCodeDto = new PzoneCodeDto();
          let splitPzonecode = track.pzoneCode.split("-");
          pzoneCodeDto.eventType = splitPzonecode[2];
          pzoneCodeDto.province = splitPzonecode[0];
          pzoneCodeDto.trackName = track.trackName;
          pzoneCodeDto.timezone = track.timezone;
          pzoneCodeDto.pzoneCode = this.dashboardService.getPzoneCode(pzoneCodeDto.trackName, pzoneCodeDto.eventType, pzoneCodeDto.province);

          if (this.dashboardService.provinceToTrackCodes.has(pzoneCodeDto.province)) {
            let tracksPr: PzoneCodeDto[] = this.dashboardService.provinceToTrackCodes.get(pzoneCodeDto.province);
            if (tracksPr.find(x => x.pzoneCode == pzoneCodeDto.pzoneCode)) {
              this.commonService.showToast(this.commonService.errorStatus, pzoneCodeDto.pzoneCode + " Already  added");
              this.spinner.hide();
              return;
            }
            tracksPr.push(pzoneCodeDto);
            this.dashboardService.provinceToTrackCodes.set(pzoneCodeDto.province, tracksPr);
          } else {
            let initTracks: PzoneCodeDto[] = [pzoneCodeDto];
            this.dashboardService.provinceToTrackCodes.set(pzoneCodeDto.province, initTracks);
          }
          this.commonService.showToast(this.commonService.successStatus, pzoneCodeDto.pzoneCode + " Added successfully");
        }
        this.spinner.hide();

        this.displayDialog = false;
      }, error => {
        this.spinner.hide();
        this.commonService.errorMessage(error);
      });
    } else {
      this.commonService.showToast(this.commonService.errorStatus, "Please select All fields");
    }
  }
  showDialogToAdd() {
    this.selectedTrackName = null;
    this.selectedEventType = null;
    this.selectedTimezone = null;
    this.province = null;
    this.selectedTrackCode = null;
    this.displayDialog = true;
  }

  onTrackNameChange(trackName) {
    if (this.province && this.selectedEventType && this.selectedTrackName) {
      let temp = trackName.replace(/  +/g, " ").trim();
      let trackNameForrmated = temp.replace(/[^a-zA-Z0-9]/g, "_");
      this.selectedTrackCode = this.province + "-" + trackNameForrmated.toUpperCase().trim() + "-" + this.selectedEventType;
    }
  }
  onProvinceChange(province) {
    if (this.selectedEventType && this.selectedTrackName) {
      let temp = this.selectedTrackName.replace(/  +/g, " ").trim();
      let trackNameForrmated = temp.replace(/[^a-zA-Z0-9]/g, "_");
      this.selectedTrackCode = province + "-" + trackNameForrmated.toUpperCase().trim() + "-" + this.selectedEventType;
    }
  }
  onEventTypeChange(eventType) {
    if (this.province && this.selectedTrackName) {
      let temp = this.selectedTrackName.replace(/  +/g, " ").trim();
      let trackNameForrmated = temp.replace(/[^a-zA-Z0-9]/g, "_");
      this.selectedTrackCode = this.province + "-" + trackNameForrmated.toUpperCase().trim() + "-" + eventType;
    }
  }


  onUpload(event) {
    if (event.files && event.files.length > 0) {
      this.spinner.show();
      this.selectedFile = event.files[0];
      this.dashboardService.uploadBackUp(this.selectedFile).subscribe(data => {
        if (data) {
          this.dashboardService.getAllTrackCode();
          this.commonService.showToast(this.commonService.successStatus, "File uploaded successfully");
        this.selectedFile=null;
        }
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.commonService.errorMessage(error);
      });

    }
  }
  exportCSV() {
    const csvExporter = new ExportToCsv(this.getOption());

    csvExporter.generateCsv(this.dashboardService.pzoneCodes);
  }

  getOption() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: "Track_Details"
    };
    return options;
  }

}
