import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/service/dashboard.service';
import { AuthService } from 'src/app/service/auth.service';
import { UserService } from 'src/app/service/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ROUTERS } from 'src/app/app.constant';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  message: string;

  constructor(private authService: AuthService, private router: Router, private spinner: NgxSpinnerService, private dashbordService: DashboardService) { }

  ngOnInit(): void {

  }
  login() {
    this.spinner.show();
    if (this.email && this.password) {
      this.authService.login(this.email, this.password).then(res => {
        if (res) {
          this.spinner.hide();
          this.dashbordService.init();
          this.dashbordService.getAllProvincesData().then(res => {
            if (res) {
              this.dashbordService.getAllTrackCode().then(res => {
                if (res) {
                  this.dashbordService.getTracksByProvince(this.dashbordService.provinces[0]);
                }
              });
            }
          })
          this.dashbordService.getAllProviders().then(res => {
            this.dashbordService.getTrackByProvider(this.dashbordService.providers[0]);
          });
          // this.router.navigateByUrl(ROUTERS.DASHBOARD);
          this.router.navigateByUrl(ROUTERS.PZONE_TRACKS);

        }
      }).catch(err => {
        this.spinner.hide();
        this.message = err.message;
      })
    } else {
      this.spinner.hide();
      this.message = "Email or password empty";
    }
  }



}

