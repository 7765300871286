import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ProviderTrack } from 'src/app/model/providerTrack';
import { DashboardService } from 'src/app/service/dashboard.service';
import { PzoneCode } from 'src/app/model/pzoneCode';
import { CommonService } from 'src/app/service/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ROUTERS } from 'src/app/app.constant';
import { Table } from 'primeng/table/table';
import { ExportToCsv } from 'export-to-csv';


@Component({
  selector: 'app-provider-track-list',
  templateUrl: './provider-track-list.component.html',
  styleUrls: ['./provider-track-list.component.scss']
})
export class ProviderTrackListComponent implements OnInit {
  @ViewChild('dt') table: Table;
  cols: any[] = [];
  displayDialog: boolean = false;
  selectedPzonecode: any;
  providerTrack: ProviderTrack;
  isEdit: boolean = false;

  selectedFile: File;
  constructor(private router: Router, public dashboardService: DashboardService, public commonService: CommonService, private spinner: NgxSpinnerService) {
  }
  showLayout(data) {
    if (data == ROUTERS.PZONE_TRACKS) {
      this.router.navigateByUrl(ROUTERS.PZONE_TRACKS)
    }
  }
  onTabChange(event) {
    this.table.reset();
    this.ngOnInit();
    this.dashboardService.providerIndex = event.index;
    this.dashboardService.getTrackByProvider(this.dashboardService.providers[event.index]);
  }

  ngOnInit(): void {
    this.cols = [
      { field: 'providerCode', header: 'Provider Code', width: '2%' },
      { field: 'pzoneCode', header: 'Pzone Code', width: '3%' },
      { field: 'provider', header: 'Provider', width: '2%' },
      { field: 'action', header: 'Action', width: '1%' },
    ];
  }
  showDialogToEdit(rowData) {
    this.isEdit = true;
    this.providerTrack = new ProviderTrack();
    this.providerTrack = rowData;
    this.selectedPzonecode = rowData.pzoneCode;
    this.displayDialog = true;
  }

  edit() {
    this.displayDialog = false;

    if (this.providerTrack.pzoneCode) {
      this.commonService.showToast(this.commonService.errorStatus, "you can't update Pzonecode");
      return;
    }
    this.spinner.show();

    if (this.selectedPzonecode) {

      this.dashboardService.mapProvider(this.providerTrack.provider, this.providerTrack.actualCode, this.selectedPzonecode).subscribe(data => {

        if (data) {
          let providerTrack: ProviderTrack = data.json();
          let splitProvider = providerTrack.providerCode.split("-");
          providerTrack.provider = splitProvider[0];

          this.dashboardService.providerTracks.set(providerTrack.providerCode, providerTrack);
          this.dashboardService.updateProviderTracks();
        }
        this.spinner.hide();

      }, error => {
        this.spinner.hide();
        this.commonService.errorMessage(error);
      });
    }

  }
  exportCSV() {
    const csvExporter = new ExportToCsv(this.getOption());
    csvExporter.generateCsv(this.dashboardService.providerTrackList);
  }
  getOption() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: "PROVIDER_DETAILS"
    };
    return options;
  }


  onUpload(event) {
    if (event.files && event.files.length > 0) {
      this.spinner.show();
      this.selectedFile = event.files[0];
      this.dashboardService.uploadProviderBackUp(this.selectedFile).subscribe(data => {
        if (data) {
          this.dashboardService.getTrackByProvider(this.dashboardService.providers[this.dashboardService.providerIndex]);
          this.commonService.showToast(this.commonService.successStatus, "File uploaded successfully");
          this.selectedFile=null;
        }
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.commonService.errorMessage(error);
      });

    }
  }
}
