import { Injectable } from '@angular/core';
import { Headers, RequestOptions } from '@angular/http';
@Injectable({
    providedIn: 'root'
})
export class UserService {

    userID: string;
    constructor() { }


    getTokenAuthHeaders() {
        let token = localStorage.getItem("token");

        const header = new Headers();
        header.set('Authorization', token)

        let tokenOptions = new RequestOptions({
            headers: header

        });
        return tokenOptions;


    }
}
