<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-clip-rotate-pulse">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<nb-layout>

    <nb-layout-header fixed>
        <app-header (showTracks)="showLayout($event)" style="width: 100%;"></app-header>
    </nb-layout-header>

    <nb-layout-column>
        <p-toast position="top-right" [showTransformOptions]="'translateX(100%)'" [baseZIndex]="5000"></p-toast>
        <p-tabView (onChange)="onTabChangeProvince($event)">
            <p-tabPanel *ngFor="let province of dashboardService.provinces let i = index"
                [selected]="i==dashboardService.indexOf">
                <ng-template pTemplate="header">
                    <span class="flag-icon flag-icon-{{province.toLowerCase()}}"></span> &nbsp;
                    <span>{{province}}</span>
                </ng-template>
            </p-tabPanel>
            <p-table #dt [columns]="cols"
                [value]="dashboardService.pzoneCodes" [paginator]="true" [rows]="20" [responsive]="true">
                <ng-template pTemplate="caption">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-6">

                            <span style="float: left;">
                                <div class="row">
                                    <div class="col-xs-4 col-md-4"><button type="button" pButton icon="pi pi-plus"
                                            (click)="showDialogToAdd()" label="Add"></button></div>
                                    <div class="col-xs-4 col-md-4"><button type="button" pButton iconPos="left"
                                            label="Export" (click)="exportCSV()"></button></div>
                                    <div class="col-xs-4 col-md-4">
                                        <p-fileUpload mode="basic" multiple="multiple" name="demo[]" url="./upload.php"
                                            [auto]="true" accept=".csv" maxFileSize="1000000"
                                            (onSelect)="onUpload($event)" chooseLabel="Import"></p-fileUpload>
                                    </div>
                                </div>

                            </span>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6">
                            <span style="float: right;font-size: 14px;">
                                <span *ngIf="dashboardService.pzoneCodes"> Count :-
                                    {{dashboardService.pzoneCodes.length}}</span> &nbsp;&nbsp;
                                <input type="text" pInputText (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Global Search" />
                            </span>
                        </div>
                    </div>


                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="text-align: center;" *ngFor="let col of columns" [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr>
                        <td *ngFor="let col of columns">
                            <span style="float: left;" class="ui-column-title">{{col.header}}</span>
                            {{rowData[col.field]}}
                        </td>
                    </tr>
                </ng-template>

            </p-table>

            <p-dialog header="Add Track Code" [(visible)]="displayDialog" [focusOnShow]="false" [responsive]="true"
                showEffect="fade" [modal]="true" [style]="{width: '400px'}">
                <div class="ui-g ui-fluid">
                    <div class="ui-g-12">
                        <div class="ui-g-4">
                            <label for="trackName">Track Name</label>
                        </div>
                        <div class="ui-g-8">
                            <input id="trackName" type="text" class="form-control" placeholder="Track Name"
                                [(ngModel)]="selectedTrackName" (ngModelChange)="onTrackNameChange($event)" pInputText>
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-4">
                            <label for="province">Province</label>
                        </div>
                        <div class="ui-g-8">
                            <p-dropdown appendTo="body" inputId="state" [options]="dashboardService.provincesDropDown"
                                (ngModelChange)="onProvinceChange($event)" [(ngModel)]="province"
                                placeholder="Select Province" filter="true"></p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-4">
                            <label for="eventType">EventType</label>
                        </div>
                        <div class="ui-g-8">
                            <p-dropdown appendTo="body" inputId="state" [options]="dashboardService.eventTypes"
                                (ngModelChange)="onEventTypeChange($event)" [(ngModel)]="selectedEventType"
                                placeholder="Select EventType" filter="true"></p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-4">
                            <label for="timezone">Timezone</label>
                        </div>
                        <div class="ui-g-8">
                            <p-dropdown appendTo="body" inputId="state"
                                [options]="dashboardService.getTimeZoneByProvince(province)"
                                [(ngModel)]="selectedTimezone" placeholder="Select Timezone" filter="true">
                            </p-dropdown>
                        </div>
                    </div>

                    <div class="ui-g-12">
                        <div class="ui-g-4">
                            <label for="trackCode">Track Code</label>
                        </div>
                        <div class="ui-g-8">
                            <input id="trackCode" type="text" class="form-control" placeholder="Track Code"
                                [(ngModel)]="selectedTrackCode" pInputText disabled>
                        </div>
                    </div>
                </div>

                <p-footer>
                    <div class="ui-dialog-buttonpane ui-helper-clearfix">
                        <button type="button" pButton icon="pi pi-check" (click)="save()" label="Save"></button>
                    </div>
                </p-footer>
            </p-dialog>
        </p-tabView>

    </nb-layout-column>
</nb-layout>


