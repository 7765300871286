import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { ROUTERS } from '../app.constant';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(public auth: AuthService,
    public router: Router) { }

  canActivate() {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate([ROUTERS.LOGIN]);
      return false;
    } else {
      return true;
    }
  }
}