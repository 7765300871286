import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './auth.service';
import { RESTSERVER } from '../app.constant';
import { SelectItem } from 'primeng/api/selectitem';
import { CommonService } from './common.service';
import { PzoneTrack } from '../model/pzoneTrack';
import { PzoneCodeDto } from '../model/pzoneCodeDto';
import { ProviderTrack } from '../model/providerTrack';
import { ProviderCode } from '../model/providerCode';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  indexOf: number;
  providerIndex: number;
  pzoneCodes: PzoneCodeDto[] = [];
  pzoneCodeList: SelectItem[] = [];
  providerTracks: Map<string, ProviderTrack> = new Map()
  providerTrackList: ProviderTrack[] = [];
  tracks: PzoneTrack[] = [];
  provinceToTrackCodes: Map<string, PzoneCodeDto[]> = new Map();
  provinceToTimeZones: Map<string, SelectItem[]> = new Map();
  eventTypes: SelectItem[] = [];
  public provinces: string[] = [];
  public providers: string[] = [];
  provincesDropDown: SelectItem[] = [];
  constructor(private http: Http, private spinner: NgxSpinnerService, private commonService: CommonService,
    private authService: AuthService) {
  }
  getAllTrackCode() {
    return new Promise((resolve, rejects) => {
      this.getAllTrackCodes().subscribe(data => {
        this.provinceToTrackCodes.clear();
        this.pzoneCodes = [];
        this.tracks = [];
        this.pzoneCodeList = [];
        if (data) {
          this.tracks = data.json();
          for (let track of this.tracks) {
            let pzoneCodeDto = new PzoneCodeDto();
 
            let splitPzoneCode = track.pzoneCode.split("-");
            pzoneCodeDto.eventType = splitPzoneCode[2];
            pzoneCodeDto.province = splitPzoneCode[0];
            pzoneCodeDto.trackName = track.trackName;
            pzoneCodeDto.timezone = track.timezone;
            pzoneCodeDto.pzoneCode = track.pzoneCode;
        
     
            this.pzoneCodeList.push({ label: pzoneCodeDto.pzoneCode, value: pzoneCodeDto.pzoneCode });
            if (this.provinceToTrackCodes.has(pzoneCodeDto.province)) {
              let tracksPr: PzoneCodeDto[] = this.provinceToTrackCodes.get(pzoneCodeDto.province);
              tracksPr.push(pzoneCodeDto);
              this.provinceToTrackCodes.set(pzoneCodeDto.province, tracksPr);
            } else {
              let initTracks: PzoneCodeDto[] = [pzoneCodeDto];
              this.provinceToTrackCodes.set(pzoneCodeDto.province, initTracks);
            }
          }
          this.pzoneCodeList = this.pzoneCodeList.sort((a, b) => {
            let data1 = a['label'];
            let data2 = b['label'];
            return data1 > data2 ? 1 : data1 < data2 ? -1 : 0;
          });
          resolve(true);

        }
      }, error => {
        this.commonService.errorMessage(error);
        rejects(error);
      });
    });
  }
  mapProvider(provoder: string, actualCode: string, pzoneCode: string) {
    let url = RESTSERVER.SERVER + "/track/auth/map-provider/" + provoder + "/" + actualCode;
    return this.http.post(url, pzoneCode, this.authService.getTokenAuthHeaders());
  }
  getAllTrackCodes() {
    return this.http.get(RESTSERVER.SERVER + "/track/auth/get-pzone-tracks", this.authService.getTokenAuthHeaders());
  }
  createTrackCode(trackCode: PzoneTrack) {
    return this.http.post(RESTSERVER.SERVER + "/track/auth/add-pzone-track", trackCode, this.authService.getTokenAuthHeaders());
  }
  getAllProvinces() {
    return this.http.get(RESTSERVER.SERVER + "/track/auth/provinces", this.authService.getTokenAuthHeaders());
  }
  getAllTimeZone() {
    return this.http.get(RESTSERVER.SERVER + "/track/auth/timezones", this.authService.getTokenAuthHeaders());
  }
  getAllEventTypes() {
    return this.http.get(RESTSERVER.SERVER + "/track/auth/eventTypes", this.authService.getTokenAuthHeaders());
  }
  getAllProvider() {
    return this.http.get(RESTSERVER.SERVER + "/track/auth/providers", this.authService.getTokenAuthHeaders());
  }
  getAllProviderTrack(provider: string) {
    return this.http.get(RESTSERVER.SERVER + "/track/auth/get-provider-tracks/" + provider, this.authService.getTokenAuthHeaders());
  }
  uploadBackUp(file: File) {
    const formData: FormData = new FormData();
    let url = RESTSERVER.SERVER + "/track/auth/uploadPzonecodeBackUp";
    formData.append("backUpFile", file);
    return this.http.post(url, formData, { headers: this.authService.getFileHeaders() });
  }

  uploadProviderBackUp(file: File) {
    const formData: FormData = new FormData();
    let url = RESTSERVER.SERVER + "/track/auth/uploadProviderBackUp";
    formData.append("backUpFile", file);
    return this.http.post(url, formData, { headers: this.authService.getFileHeaders() });

  }
  getTracksByProvince(province: string) {
    if (province) {
      this.pzoneCodes = [];
      this.pzoneCodes = this.provinceToTrackCodes.get(province);
    }
  }
  getTrackByProvider(provider: string) {

    this.getAllProviderTrack(provider).subscribe(data => {
      this.providerTrackList = [];
      this.providerTracks.clear();
      let providerTracks = data.json();

      for (let providerTrack of providerTracks) {
        let providerTrackDto: ProviderTrack = new ProviderTrack();
        providerTrackDto.actualCode = providerTrack.actualCode;
        providerTrackDto.providerCode = providerTrack.providerCode;
        let providerCodeSplit = providerTrackDto.providerCode.split("-");
        providerTrackDto.provider = providerCodeSplit[0];
        if (providerTrack.pzoneCode) {
          providerTrackDto.pzoneCode = providerTrack.pzoneCode;
        }else{
          providerTrackDto.pzoneCode = null;
        }
        this.providerTracks.set(providerTrackDto.providerCode, providerTrackDto);
      }
      this.updateProviderTracks();

    }, error => {
      this.commonService.errorMessage(error);
    });
  }
  updateProviderTracks() {
    this.providerTrackList = Array.from(this.providerTracks.values());
  }

  generateCodeStr(providerCode: ProviderCode) {
    return providerCode.provider + "-" + providerCode.formattedCode;
  }
  getTimeZoneByProvince(province: string) {
    return this.provinceToTimeZones.get(province);
  }

  getPzoneCode(trackName: string, eventType: string, province) {

    let temp = trackName.replace(/  +/g, " ").trim();
    let trackNameForrmated = temp.replace(/[^a-zA-Z0-9]/g, "_");
    return province.trim() + "-" + trackNameForrmated.toUpperCase().trim() + "-" + eventType;

  }
  init() {
    this.getAllTimezone();
    this.getAllEventType();
  }
  getAllProviders() {
    return new Promise((resolve, rejects) => {
      this.providers = [];
      this.getAllProvider().subscribe(data => {
        if (data) {
          this.providers = data.json();
          this.providers = this.providers.sort((a, b) => {
            return a > b ? 1 : a < b ? -1 : 0;
          });
          resolve(true);
        }

      }, error => {
        this.commonService.errorMessage(error);
      });
    });
  }

  getAllProvincesData() {
    return new Promise((resolve, rejects) => {
      this.getAllProvinces().subscribe(data => {
        if (data) {
          let provinces = data.json();
          this.provinces = provinces.sort((a, b) => {
            let data1 = b;
            let data2 = a;
            return data1 > data2 ? -1 : data1 < data2 ? 1 : 0;
          });
          this.provincesDropDown = [];
          for (let province of this.provinces) {
            this.provincesDropDown.push({ label: province + "", value: province });
          }
          resolve(true);
        }
      }, error => {

        this.commonService.errorMessage(error);
      });
    });
  }
  getAllTimezone() {
    this.getAllTimeZone().subscribe(res => {
      this.provinceToTimeZones.clear();
      if (res) {
        let data = res.json();
        let keys = Object.keys(data);
        for (let key of keys) {
          let timezones = data[key];
          let seletedItem: SelectItem[] = [];
          for (let timexone of Array.from(timezones)) {
            seletedItem.push({ label: timexone + "", value: timexone });
          }
          this.provinceToTimeZones.set(key, seletedItem);
        }
      }
    }, error => {
      this.commonService.errorMessage(error);
    });
  }
  getAllEventType() {
    this.spinner.show();
    this.getAllEventTypes().subscribe(res => {
      this.eventTypes = [];
      if (res) {
        let data = res.json();
        let keys = Object.keys(data);
        for (let key of keys) {
          this.eventTypes.push({ label: data[key], value: data[key] });
        }
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.commonService.errorMessage(error);
    });
  }

}