//angular 
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { JwtModule } from '@auth0/angular-jwt';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


//routing
import { AppRoutingModule } from './app-routing.module';

//component
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';


//nebular
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbThemeModule, NbLayoutModule, NbCardModule, NbButtonModule } from '@nebular/theme';


//ngx dropdown for header
import { HttpModule } from '@angular/http';
import { NgxSpinnerModule } from "ngx-spinner";
import { HttpClientModule } from '@angular/common/http';

import { FileUploadModule } from 'primeng/fileupload';

//primeng
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { KeyFilterModule } from 'primeng/keyfilter';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
//Firebase
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireDatabaseModule } from 'angularfire2/database';

//environment
import { environment } from 'src/environments/environment';
import { AngularFirestore } from 'angularfire2/firestore';
import { TrackListComponent } from './components/track-list/track-list.component';
import { MessageService } from 'primeng/api';
import { ProviderTrackListComponent } from './components/provider-track-list/provider-track-list.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    HeaderComponent,
    TrackListComponent,
    ProviderTrackListComponent,


  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    TableModule,
    HttpClientModule,
    NbCardModule,
    NbButtonModule,
    FormsModule,
    HttpModule,
    KeyFilterModule,
    DropdownModule,
    AngularFireDatabaseModule,
    FileUploadModule,
    AngularFireAuthModule,
    AppRoutingModule,
    NgxSpinnerModule,
    TooltipModule,
    DialogModule,
    InputTextModule,
    ToastModule,
    TabViewModule,
    ButtonModule,
    JwtModule.forRoot({}),
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [DatePipe, AngularFirestore, MessageService],
  entryComponents: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
